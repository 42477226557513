// extracted by mini-css-extract-plugin
export var addToCartBox = "gel-module--add-to-cart-box--7114a";
export var addToCartLarge = "gel-module--addToCartLarge--7bdb8";
export var boxContent = "gel-module--box-content--378ca";
export var boxDetails = "gel-module--box-details--a6008";
export var boxHeader = "gel-module--box-header--22b92";
export var dotted = "gel-module--dotted--0a572";
export var features = "gel-module--features--7ce78";
export var gelContent = "gel-module--gel-content--d178e";
export var gelName = "gel-module--gel-name--e5547";
export var gelTop = "gel-module--gel-top--562b4";
export var gelWrapper = "gel-module--gel-wrapper--33e28";
export var listFoundation = "gel-module--list-foundation--8067a";
export var listHeaderGel = "gel-module--list-header-gel--17791";
export var listIngredients = "gel-module--list-ingredients--5d7b1";
export var microgelIcon = "gel-module--microgel-icon--629fc";
export var pFormula = "gel-module--p-formula--2c582";
export var pFoundation = "gel-module--p-foundation--e3e00";
export var price = "gel-module--price--ad1fe";
export var purpleColor = "gel-module--purple-color--cb2a4";
export var qrIcon = "gel-module--qr-Icon--7e051";
export var size10 = "gel-module--size-10--ecf11";
export var size18 = "gel-module--size-18--10afd";
export var summaryIngredients = "gel-module--summary-ingredients--1887a";