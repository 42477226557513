import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as Styles from "../../components/style/gel.module.scss"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { StaticImage } from "gatsby-plugin-image"
import axios from "axios";
import _ from "lodash"
//import {navigate} from 'gatsby'; //import navigate from gatsby

import IconBox1 from '../../images/svg/icon-healthy_aging.inline.svg'
import IconBox2 from '../../images/svg/weight-management.inline.svg'
import IconBox3 from '../../images/svg/icon-stress_and_mood.inline.svg'
import IconBox4 from '../../images/svg/icon-gut_health.inline.svg'
import IconBox5 from '../../images/svg/icon-energy.inline.svg'
import IconBox6 from '../../images/svg/icon-focus_and_brain.inline.svg'
import IconBox7 from '../../images/svg/icon-workout_and_recovery.inline.svg'
import IconBox8 from '../../images/svg/icon-immunity.inline.svg'
import IconBox9 from '../../images/svg/icon-environment.inline.svg'
import IconQr from '../../images/svg/icon-qr_hc.inline.svg'
//import SupplementFactsImg from '../../images/svg/supplement_facts.inline.svg'

//import HcWhiteLogo from '../../images/svg/hc_logo_whitev2.inline.svg'
import HcBlackLogo from '../../images/svg/hc_logo_black.inline.svg'

const {GATSBY_CHECK_RESULTS} = process.env;
const {GATSBY_TRIGGER_ADDTOCART} = process.env;

const GelPage = (props) => {
	const access_code = props.params.access_code
	const [ questionarieData, setQuestionarieData ] = React.useState([]);
	const [ addToCartStatus, setaddToCartStatus ] = React.useState({'sending': false , 'error': false , 'message': ''});

	const checkQuestionarieResult = React.useCallback(async () => {
		var requestData = {access_code : access_code ,
					  	   type : 'get_result'
					  	}
		await axios.get(GATSBY_CHECK_RESULTS , { params: requestData })
				.then((res) => {
					setQuestionarieData(res.data);
				})
				.catch((error) => { 
					var result = {'request' : false};
					if (error.response && typeof error.response.data === 'object') {
						if(error.response.data.errors) {
							result.errors = _.flatten(_.toArray(error.response.data.errors));
						} else if (error.response.data.message) {
							result.errors = [error.response.data.message];
						}
					} else {
						result.errors = ['Something went wrong. Please try again.'];
					}
					setQuestionarieData(result);
				});
	},[access_code])

	React.useEffect( () => {
		checkQuestionarieResult()
	}, [checkQuestionarieResult]); // execute in the first render

	const addToCartEvent = (url_redirect) => {
		setaddToCartStatus({...addToCartStatus , sending: true , error: false , message:''})
		axios.post(GATSBY_TRIGGER_ADDTOCART , { access_code : access_code})
				.then((res) => {
					setaddToCartStatus({...addToCartStatus , sending: false , error: false , message:''})
					window.location.href = url_redirect;
				})
				.catch((error) => {
					var messageText = "Something went wrong. Please try again.";
					setaddToCartStatus({...addToCartStatus , error: true , message: messageText});
				});
	}

	const formatTitle = (string) => {
		return _.replace( _.startCase(string), 'And', '&');
	}

	const startsWithVowel = (word) => {
		var vowels = ("aeiouAEIOU");
		return vowels.indexOf(word[0]) !== -1;
	}

	const splitValues = (word , position) => {
		var myarr = word.split(" ");
		if(position != null && myarr[position] !== undefined) {
			return myarr[position];
		}
		return '';
	}

	const searchFitnessActivity = (code_search) => {
		var items = [{code : 'a' , label : 'I rarely or never workout (1 day / week or less) but would like to be more active'}, {code : 'b' , label : 'I sometimes work out (2 days per week)'},
		{code : 'c' , label : 'I work out often (3 to 4 days per week)'} , {code : 'd' , label : 'I am in great shape and I work out all the time (5 to 7 days per week)'},
		{code : 'e' , label : 'I am an athlete or competitor (5 to 7 days per week)'}];

		var label = "";

		items.forEach(input => {
			if(input.code === code_search) {
				label = input.label
			}
		});

		return label ? label : code_search;
	}

	return (
		<>
		<Layout classToMain="gel-results">
			<Container className="min-vh-100 custom-container">
				{(questionarieData.request && questionarieData.request === true ) ? <>
					<Row className="py-5">
						<Col xs="12">
							<h1 className="mb-5"><b>Your Personalized Formula</b></h1>
						</Col>
					</Row>
					<Row className="">
						<Col xs="12">
							<h2 className="mb-4">Tailored To You & Your Unique Needs.</h2>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col xs="12" lg="8">
							<div className="d-flex flex-column h-100">
								<div className="pe-0 pe-md-5">
									<p className={`mb-4 ${Styles.size18}`}>Your 30-day supply of your personalized gel supplement is designed to support your immediate qualify of life and long-term health with the right nutrients for your unique body.</p>
								</div>
							</div>
							<div>
								<p>Based on your responses to the questionnaire we are adding a base of nutritional support that includes custom doses of the following ingredients:</p>
								<Row>
									<Col>
										<ul>
											<li>Vitamin A</li>
											<li>Vitamin C</li>
											<li>Vitamin D</li>
											<li>Vitamin E</li>
											<li>Thiamin</li>
										</ul>
									</Col>
									<Col>
										<ul>
											<li>Riboflavin</li>
											<li>Niacinamide</li>
											<li>Vitamin B6</li>
											<li>Vitamin B12</li>
											<li>Biotin</li>
										</ul>
									</Col>
									<Col>
										<ul>
											<li>Pantothenic Acid</li>
											<li>Iodine</li>
											<li>Magnesium</li>
											<li>Zinc</li>
											<li>Selenium</li>
										</ul>
									</Col>
								</Row>
								{(questionarieData.data && questionarieData.data.all_ingredients ) &&
									<>
										<div className={`my-3 p-4 ${Styles.summaryIngredients}`}>
											<h4 className="mb-4">Your Personalized Formulation Includes</h4>
											<div className={`d-grid ${Styles.listIngredients}`}>
											{Object.entries(questionarieData.data.all_ingredients).map(([ingredient_key, ingredient]) => (
												<>
												<div className="d-flex align-content-center justify-content-between align-items-baseline">
													<div>{ingredient_key}</div><div className={`flex-fill mx-2 ${Styles.dotted}`}></div> <div>{ingredient.value}</div>
												</div>
												</>
											))
											}
											</div>
										</div>
									</>
								}
								<p>The tiles below offer an explanation of how we personalized these ingredients as well as other nutrients we added to uniquely support you.</p>
							</div>
							<div className="mb-3 mb-lg-0">
								<Button disabled={addToCartStatus.sending} onClick={event => addToCartEvent("https://www.healthycell.com/products/personalized-formula?variant=43185165238440")} className={`${Styles.addToCartLarge} rounded-0`} variant="primary">
									Add to Cart
									{ addToCartStatus.sending &&
										<>
										<span className="ms-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
										</>
									}
								</Button>
								{addToCartStatus.error && addToCartStatus.error === true &&
									<>
									<div className="alert alert-info mt-3">
										{addToCartStatus.message}
									</div>
									</>
								}
							</div>
						</Col>
						<Col xs="12" lg="4">
							<Row className="align-items-end">
								<Col>
									<div className={`${Styles.gelWrapper} mb-3 mb-md-0 d-flex flex-column mx-auto mx-lg-0`}>
										<div className={`${Styles.gelTop}`}>
											<StaticImage
												src="../../images/get_top_icon.png"
												loading="lazy"
												height={65}
												formats={["auto", "webp", "avif"]}
												alt="gel"
												placeholder="none"
											/>
										</div>
										<div className={`p-4 rounded-4 d-flex flex-column justify-content-between ${Styles.gelContent} h-100 ${Styles.microgelIcon}`}>
											<div className="mt-2">
												<HcBlackLogo />
												<IconQr className={`${Styles.qrIcon} mt-3 mb-2`}/>
											</div>
											<div className="mt-3">
												<p className={`${Styles.pFormula} mb-1`}>Personalized for:</p>
												<h4 className={`${Styles.gelName} mb-3`}>{(questionarieData.data && questionarieData.data.name ) && <> {questionarieData.data.name} </>}</h4>
												{(questionarieData.data && questionarieData.data.tiles ) &&
												<>
													<p className={`${Styles.pFoundation} mb-1`}><b>Daily Multinutrient</b></p>
													<ul className={`${Styles.listFoundation} list-inline mb-0`}>
													{Object.entries(questionarieData.data.tiles).map(([tile_key, tile]) => (
														(tile !== false) && 
														<React.Fragment key={`daily_foundation_D${tile_key}`}>
															<li key={`daily_foundation_${tile_key}`}>+ {formatTitle(tile_key)}</li>
														</React.Fragment>
														))
													}
													</ul>
												</>
												}
											</div>

										</div>
									</div>
								</Col>
								{/*<Col>
									<div>
										<SupplementFactsImg />
									</div>
								</Col>*/}
							</Row>
						</Col>
					</Row>
					{(questionarieData.data && questionarieData.data.tiles ) &&
					<>
						<Row className="mt-5">
							{Object.entries(questionarieData.data.tiles).map(([tile_key, tile]) => (
									(tile !== false) && <React.Fragment key={`segmenttitle_${tile_key}`}>
										<Col xs="12" lg="3" key={`title_${tile_key}`}>
											<div className={`d-flex flex-column ${Styles.boxDetails} h-100 pb-4`}>
												<div className={`${Styles.boxHeader}`}>
													<div className="align-items-center d-flex justify-content-center p-3">
														{tile_key === "healthy_aging" &&
															<><IconBox1/></>
														}
														{tile_key === "weight_management" &&
															<><IconBox2/></>
														}
														{tile_key === "stress_and_mood" &&
															<><IconBox3/></>
														}
														{tile_key === "gut_health" &&
															<><IconBox4/></>
														}
														{tile_key === "energy" &&
															<><IconBox5/></>
														}
														{tile_key === "brain_health_and_focus" &&
															<><IconBox6/></>
														}
														{tile_key === "workout_and_recovery" &&
															<><IconBox7/></>
														}
														{tile_key === "immunity" &&
															<><IconBox8/></>
														}
														{tile_key === "environment" &&
															<><IconBox9/></>
														}
													</div>
												</div>
												<div className={`p-4 ${Styles.boxContent} h-100`}>
													<h5 className="text-center"><b>{formatTitle(tile_key)}</b></h5>
													{tile_key === "healthy_aging" &&
														<>
														<p>Based on your age, {tile.user_answer}, we are 
														{tile.user_answer < 30 &&
															<>
															<span> recommending only a small boost in full spectrum macronutrient to cover any vitamin and mineral gaps in your diet.</span>
															</>
														}
														{tile.user_answer >= 30 &&
															<>
															<span> including a full spectrum macronutrient to cover any vitamin and mineral gaps in your diet{tile.user_answer >= 50 ? ',' : '.'}</span>
															</>
														}
														{tile.user_answer >= 50 &&
															<>
															<span>  or from a GI tract with less efficient absorption due to age.</span>
															</>
														}
														</p>
														</>
													}
													{tile_key === "weight_management" &&
														<>
														<p>Based on your BMI of {tile.user_answer} we are boosting your key nutrient intake to {startsWithVowel(tile.dose_level) ? 'an' : 'a'} {tile.dose_level} level to fit your body's needs.</p>
														</>
													}
													{tile_key === "stress_and_mood" &&
														<>
														<p>We recommed that you get at least {tile.value_dose} of Vitamin D to balance your stress and mood.</p>
														</>
													}
													{tile_key === "gut_health" &&
														<>
														<p>We recommend you add {splitValues(tile.value_dose , 0)} of fiber and prebiotics to your daily intake.</p>
														</>
													}
													{tile_key === "energy" &&
														<>
														<p>We recommend you get a {splitValues(tile.value_dose , 0)} boost of caffeine and other key nutrients to support your lifestyle. If you workout regularly, we recommend that you use this product as a pre-workout to help you power through!</p>
														</>
													}
													{tile_key === "brain_health_and_focus" &&
														<>
														<p>We recommed increasing your vitamin B6 and B12 along with natural caffeine to keep you sharp!</p>
														</>
													}
													{tile_key === "workout_and_recovery" &&
														<>
														<p>Based on your workout plan "{searchFitnessActivity(tile.user_answer)}" we recommend boosting your micronutrient and antioxidant intake to {startsWithVowel(tile.dose_level) ? 'an' : 'a'} {tile.dose_level} level. Plus, we added {tile.value_dose} so you can use this product as a pre-workout to help you power through!</p>
														</>
													}
													{tile_key === "immunity" &&
														<>
														<p>Based on your desire to support your immune system, we increased the doses of several key immune-boosting nutrients, like Vitamin C and Vitamin D, and added others like Elderberry and Beta Glucan to support healthy immune function.</p>
														</>
													}
													{tile_key === "environment" &&
														<>
														<p>Based on your limited sun exposure, we are increasing your Vitamin D intake to make sure you're covered.</p>
														</>
													}
													{(tile_key !== "healthy_aging" && tile_key !== "weight_management") && tile.added  &&
														<>
															<h5>We added:</h5>
															<p>
																<span dangerouslySetInnerHTML={{ __html: tile.added }} />
															</p>
														</>
													}
												</div>
											</div>
										</Col>
									</React.Fragment>
								))
							}
						</Row>
					</>
					}

					<div className={`mt-3 p-4 ${Styles.addToCartBox}`}>
						<Row className="align-items-center">
							<Col xs="12" lg="4">
								<p className="mb-0"><span className="text-decoration-line-through">$199</span> <span className={`${Styles.price}`}><b>$99</b></span></p>
								<p className={`${Styles.purpleColor}`}><i>Save 50% - for limited time</i></p>
								<Button disabled={addToCartStatus.sending} onClick={event => addToCartEvent("https://www.healthycell.com/products/personalized-formula?variant=43185165238440")} className='rounded-0 px-4' variant="primary">
									Add to Cart
									{ addToCartStatus.sending &&
										<>
										<span className="ms-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
										</>
									}
								</Button>
								{addToCartStatus.error && addToCartStatus.error === true &&
									<>
									<div className="alert alert-info mt-3">
										{addToCartStatus.message}
									</div>
									</>
								}
							</Col>
							<Col xs="12" lg="8">
								<ul className={`${Styles.features} ${Styles.size18}`}>
									<li>Monthly plan, shipped & billed monthly (cancel any time)</li>
									<li>Update your survey anytime and your formula will adapt</li>
									<li>Unlimited access to your personal nutritionist</li>
								</ul>
							</Col>
						</Row>
					</div>
				</>
				: <> 
					{( !questionarieData.errors || (questionarieData.errors && questionarieData.errors.length === 0) ) &&
						<Row>
							<Col>
								<div className="text-center py-2">
									<div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								</div>
							</Col>
						</Row>
					}
				</> }

				{( questionarieData.errors && questionarieData.errors.length > 0 ) &&
					<>
					<Row>
						<Col>
							<div className="mt-4">
								<div className="alert alert-danger">
									<ul className="mb-0">
										{questionarieData.errors.map((error , ocheck_i) => (
												<li key={`error_list_${ocheck_i}`}>
													{ error }
												</li>
											))
										}
									</ul>
								</div>
							</div>
						</Col>
					</Row>
					</>
				}
			</Container>
		</Layout>
		</>
	)
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Gel Healthycell" />

export default GelPage
